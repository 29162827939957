@import 'libs/IdeLink.Web.Common/src/theme';

html,
body,
#root {
    margin: 0;
    height: 100%;
}

::-webkit-scrollbar {
    display: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: $fontFamily;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Toastify {
    .Toastify__close-button {
        margin: auto;
    }
}